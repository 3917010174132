<template>
  <div class="container py-5">
    <p class="fw-bold">AMWC 2024</p>
    <p>時間：2024/02/22-24</p>
    <p>地點：Miami Beach Convention Center</p>
    <p>展位：#330</p>

    <p>
     We will be attending the AMWC 2024 Conference in Miami this year!! Visit our booth #330 for a live demo of our Skin Imaging System, The Observ 520x, and learn more about our latest feature releases.
    <br><br>
     AMWC (Aesthetics & Anti-Aging Medicine World Congress) Americas is a premium aesthetic conference reputed for producing a top-tier scientific program presented by leading KOLs and speakers. This conference offers evidence-based, cutting-edge scientific content, for all medical aesthetic practitioners. 
    <br><br>
     AMWC 2024 will take place at the Miami Beach Convention Center February 22-24th.
     <br><br>
      We hope to see you there!!!
    </p>

    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_34.jpg"
    />


  </div>
</template>
<script>
export default {};
</script>
